import React from 'react';
import { Avatar } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import PROFILE_IMAGE from '@app/assets/images/profile-image.jpg';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Avatar shape="circle" src={PROFILE_IMAGE} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.firstname} ${profileData?.lastname}`}</S.Title>
      <S.Subtitle>{profileData?.username}</S.Subtitle>
    </S.Wrapper>
  ) : null;
};
