import axios from 'axios';
import { AxiosError } from 'axios';
import { persistToken, readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

    return config;
});

httpApi.interceptors.response.use(undefined, async (error: AxiosError) => {
    // throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
    if (error.response?.status === 401) {
        const apiResponse = await httpApi.post('/refresh');
        persistToken(apiResponse.data.access_token);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.config.headers = { ...error.config.headers, Authorization: `Bearer ${readToken()}` };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return axios(error.config);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    } else if (error.response?.status === 500 && error.response?.data.exception.includes('TokenExpiredException')) {
        window.location.replace('/logout');
    } else {
        return Promise.reject(error);
    }
});

export interface ApiErrorData {
    message: string;
}
