import styled from 'styled-components';
import { default as AntIcon } from '@ant-design/icons';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { StatisticColor } from '@app/constants/config/statistics';
import { Text } from '../StatisticsInfo/StatisticsInfo.styles';
import { Typography } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';

interface StatisticsProps {
  $color: StatisticColor;
}

export const IconWrapper = styled.div`
  margin-top: 0.25rem;
`;

export const Icon = styled(AntIcon)`
  font-size: 2.75rem;
`;

export const StatisticCard = styled(DashboardCard)`
  line-height: 1;
  overflow: hidden;
`;

export const ValueText = styled(Typography.Text)`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxl};
  }
`;
