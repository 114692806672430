import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { BarsOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from '@app/components/common/Carousel/Carousel';
import * as S from './RecentlyAddedServices.styles';
import { getRecentServices, Service } from '@app/api/service.api';
import { SectionCardHeader } from '@app/components/common/Card/SectionCardHeader/SectionCardHeader';
import { ViewAll } from '@app/components/common/ViewAll/ViewAll';
import { RecentCard } from '@app/pages/DashboardPages/Recent/Services/nft-card/RecentCard';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Result } from '@app/components/common/Result/Result';

export const RecentlyAddedServices: React.FC = () => {
  const [recentServices, setRecentServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRecentServices(10)
      .then((results) => {
        setRecentServices(results);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const cards = useMemo(() => {
    return recentServices.map((recentService) => (
      <div key={recentService.serviceid}>
        <S.CardWrapper>
          <RecentCard recentItem={recentService} />
        </S.CardWrapper>
      </div>
    ));
  }, [recentServices]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>();

  return (
    <>
      <SectionCardHeader title="Recently Added Jobs">
        <Row align="middle">
          <Col>
            <ViewAll bordered={false} onLinkClick={() => (window.location.href = '/services')} />
          </Col>

          <Col>
            <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
              <LeftOutlined />
            </S.ArrowBtn>
          </Col>

          <Col>
            <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
              <RightOutlined />
            </S.ArrowBtn>
          </Col>
        </Row>
      </SectionCardHeader>

      <S.SectionWrapper>
        {isLoading ? (
          <Spinner size="default" />
        ) : recentServices.length > 0 ? (
          <Carousel
            ref={sliderRef}
            slidesToShow={3}
            infinite={recentServices.length > 3}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: 2,
                },
              },
            ]}
          >
            {cards}
          </Carousel>
        ) : (
          <Result status="warning" icon={<BarsOutlined />} title="No recent services found!" />
        )}
        {}
      </S.SectionWrapper>
    </>
  );
};
