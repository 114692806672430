import { shadeColor } from '@app/utils/utils';
import { graphic } from 'echarts';
import {
    BASE_COLORS,
    primaryBlue,
    primaryDark,
    primaryGreen,
    primaryLight,
    primaryRed,
    primaryYellow
} from "../constants";
import { ITheme } from '../types';

const chartColors = {
    chartTooltipLabel: '#6A7985',
    chartColor1: '#01509A',
    chartColor1Tint: '#2983D8',
    chartColor2: '#35A0DC',
    chartColor2Tint: '#67C5FA',
    chartColor3: '#FFB155',
    chartColor3Tint: '#FFA800',
    chartColor4: '#31A652',
    chartColor4Tint: '#89DCA0',
    chartColor5: '#FF5252',
    chartColor5Tint: '#FFC1C1',
    chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(0, 110, 211, 0.5)',
        },
        {
            offset: 1,
            color: 'rgba(255, 225, 255, 0)',
        },
    ]),
    chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 82, 82, 0.5)',
        },
        {
            offset: 1,
            color: 'rgba(255, 255, 255, 0)',
        },
    ]),
    chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 255, 255, 0)',
        },
        {
            offset: 1,
            color: 'rgba(255, 82, 82, 0.5)',
        },
    ]),
};

export const lightColorsTheme: ITheme = {
    primary: primaryGreen['500'],
    primary1: '#f5f5f5',
    light: '#C5D3E0',
    secondary: primaryYellow['500'],
    error: primaryRed['300'],
    warning: primaryYellow['800'],
    success: primaryGreen['600'],
    background: primaryLight['300'],
    secondaryBackground: '#F8FBFF',
    secondaryBackgroundSelected: shadeColor('#F8FBFF', -5),
    additionalBackground: '#ffffff',
    collapseBackground: 'rgb(0, 108, 207)',
    timelineBackground: '#F8FBFF',
    siderBackground: '#ffffff',
    spinnerBase: '#f42f25',
    scroll: '#c5d3e0',
    border: '#cce1f4',
    borderNft: '#79819A',
    textMain: '#404040',
    textLight: '#9A9B9F',
    textSuperLight: '#BEC0C6',
    textSecondary: BASE_COLORS.white,
    textDark: '#404040',
    textFieldViewerTitle: primaryDark['300'],
    textFormLabel: primaryDark['300'],
    textNftLight: primaryYellow['900'],
    textSiderPrimary: primaryYellow['500'],
    textSiderSecondary: primaryLight['100'],
    textTableLink: primaryYellow['900'],
    subText: 'rgba(0, 0, 0, 0.45)',
    shadow: 'rgba(0, 0, 0, 0.07)',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
    boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
    boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    boxShadowNftSecondary:
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    dashboardMapBackground: '#EAF5FF',
    dashboardMapCircleColor: '#9BC2E7',
    dashboardMapControlDisabledBackground: '#c5d3e0',
    notificationSuccess: '#EFFFF4',
    notificationPrimary: '#D7EBFF',
    notificationWarning: '#FFF4E7',
    notificationError: '#FFE2E2',
    heading: '#13264d',
    borderBase: '#bec0c6',
    disable: 'rgba(0, 0, 0, 0.25)',
    disabledBg: '#c5d3e0',
    layoutBodyBg: '#E9E9E9',
    layoutHeaderBg: 'transparent',
    layoutSiderBg: 'linear-gradient(261.31deg, #528E4D -29.57%, #085D00 121.11%)',
    inputPlaceholder: '#404040',
    itemHoverBg: '#f5f5f5',
    backgroundColorBase: '#F5F5F5',
    avatarBg: '#ccc',
    alertTextColor: BASE_COLORS.white,
    breadcrumb: 'rgba(0, 0, 0, 0.45)',
    icon: '#a9a9a9',
    iconHover: 'rgba(0, 0, 0, 0.75)',
    spinnerBackground: primaryGreen['500'],
    stepIcon: primaryGreen['500'],
    stepCompletedIcon: primaryGreen['500'],
    stepCompletedTitle: primaryGreen['500'],
    dividerColor: primaryDark['50'],
    statics: {
        completed: primaryGreen['400'],
        pending: primaryYellow['800'],
        due: primaryRed['300'],
    },
    icons: {
        success: primaryGreen['400'],
        error: primaryRed['500'],
        warning: primaryYellow['800'],
        info: primaryBlue['A200'],
        delete: primaryRed['500'],
    },
    ...chartColors,
};
