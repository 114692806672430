import React, { useCallback, useEffect, useState } from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { SchedulePanel } from './SchedulePanel';
import { AppDate, Dates } from 'constants/Dates';
import { Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ServiceCalendar } from '@app/components/common/Schedule/ServiceCalender/ServiceCalendar/ServiceCalendar';
import { getServiceSchedules, Service, ServiceEvent } from '@app/api/service.api';
import { Dayjs } from 'dayjs';
import { Spinner } from '@app/components/common/Spinner/Spinner';

interface ScheduleCardProps {
  direction: 'vertical' | 'horizontal';
}

export const ScheduleCard: React.FC<ScheduleCardProps> = (props) => {
  const { isTablet } = useResponsive();

  const [selectedDate, setSelectedDate] = useState<AppDate>(Dates.getToday());
  const [isLoading, setIsLoading] = useState(false);
  const [isDateClicked, setDateClicked] = useState(false);
  const [serviceEvents, setServiceEvents] = useState<ServiceEvent[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);

  const getSchedules = useCallback((year: number, month: number) => {
    setIsLoading(true);
    getServiceSchedules(year, month + 1)
      .then((schedules) => {
        const events = Object.keys(schedules).map((value: string) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { date: value, doctor: 5, services: schedules[value] };
        });
        setServiceEvents(events);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getSchedules(selectedDate.year(), selectedDate.month());
  }, [getSchedules, selectedDate]);

  const onDecreaseMonth = () => {
    setSelectedServices([]);
    setSelectedDate(selectedDate.month(selectedDate.month() - 1));
  };

  const onIncreaseMonth = () => {
    setSelectedServices([]);
    setSelectedDate(selectedDate.month(selectedDate.month() + 1));
  };

  const onToday = () => {
    setSelectedDate(Dates.getToday());
  };

  const onDateSelect = (date: Dayjs) => {
    setSelectedDate(date);
    const currentEvent = serviceEvents.filter((event) => event.date === date.format('YYYY-MM-DD'));
    if (currentEvent != null && currentEvent.length > 0) {
      setSelectedServices(currentEvent[0].services);
    } else {
      setSelectedServices([]);
    }
  };

  const calendarItem = (
    <ServiceCalendar
      calendar={serviceEvents}
      date={selectedDate}
      setDate={onDateSelect}
      onDecrease={onDecreaseMonth}
      onIncrease={onIncreaseMonth}
      onToday={onToday}
      setDateClicked={setDateClicked}
    />
  );

  if (isLoading) {
    return <Spinner size="large" />;
  }

  return (
    <>
      <RowStyled gutter={[10, 10]} wrap={props.direction === 'vertical'}>
        {isTablet ? (
          <>
            <Col md={props.direction === 'vertical' ? 24 : 12}>{calendarItem}</Col>
            <Col md={props.direction === 'vertical' ? 24 : 12}>
              <SchedulePanel direction="vertical" events={selectedServices} />
            </Col>
          </>
        ) : isDateClicked && serviceEvents.some((event) => Dates.getDate(event.date).isSame(selectedDate, 'date')) ? (
          <BackButtonWrapper span={24}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => setDateClicked(false)} />
            <SchedulePanel direction="vertical" events={selectedServices} />
          </BackButtonWrapper>
        ) : (
          <Col span={24}>{calendarItem}</Col>
        )}
      </RowStyled>
    </>
  );
};

const BackButtonWrapper = styled(Col)`
  position: relative;
`;

const RowStyled = styled(Row)`
  min-height: 21.75rem;
`;
