import React from 'react';
import { Space } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getDifference } from '@app/utils/utils';
import * as S from './StatisticsInfo.styles';

interface StatisticsInfoProps {
  name: string;
  value: number;
}

export const StatisticsInfo: React.FC<StatisticsInfoProps> = ({ name, value }) => {
  return (
    <Space direction="vertical" size={6} style={{ padding: 5 }}>
      <S.Title>{name}</S.Title>
      <S.Text># of {name.toLowerCase()} jobs</S.Text>
    </Space>
  );
};
