import React from 'react';
import { Dates } from '@app/constants/Dates';
import * as S from './ScheduleListItem.styles';
import { Service } from '@app/api/service.api';
import { Link } from 'react-router-dom';
import { Space } from 'antd';

interface ScheduleListItemProps {
  event: Service;
}

export const ScheduleListItem: React.FC<ScheduleListItemProps> = ({ event }) => {
  return (
    <S.ActivityCard>
      <S.Wrapper>
        <S.InfoWrapper>
          <S.InfoHeaderWrapper>
            <S.TitleWrapper>
              <Space size="small">
                <Link to={`/services/service/${event.serviceid}`} target={'_blank'}>
                  <S.Title level={5}>{atob(event.reference)}</S.Title>
                </Link>
              </Space>
            </S.TitleWrapper>
            <S.Text>Status: {event.servicestatusname}</S.Text>
            <Space size="small">
              <Link target={'_blank'} to={`/customers/customer/${event.customerid}`}>
                <S.Text>Customer: {event.customername}</S.Text>
              </Link>
            </Space>
          </S.InfoHeaderWrapper>

          <S.InfoBottomWrapper>
            <S.DateText>{Dates.getDate(event.creadtedon).format('lll')}</S.DateText>
          </S.InfoBottomWrapper>
        </S.InfoWrapper>
      </S.Wrapper>
    </S.ActivityCard>
  );
};
