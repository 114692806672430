import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './DashboardPage.styles';
import { getSummary, SummaryResults } from '@app/api/summary.api';
import { ServiceStatistics } from '@app/pages/DashboardPages/Statics/ServiceStatistics';
import { RecentlyAddedServices } from '@app/pages/DashboardPages/Recent/Services/RecentlyAddedServices';
import { ScheduleCard } from '@app/components/common/Schedule/ServiceCalender/ScheduleCard';
import { Spinner } from '@app/components/common/Spinner/Spinner';

const DashboardPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState<SummaryResults>();

  const fetchSummary = () => {
    setIsLoading(true);
    getSummary()
      .then((results) => setSummary(results))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={16} xxl={17}>
        <Row gutter={[60, 60]}>
          <Col span={24}>
            {isLoading ? (
              <Spinner size="small" />
            ) : (
              summary != null && <ServiceStatistics services={summary?.services} />
            )}
          </Col>
          <Col span={24}>
            <RecentlyAddedServices />
          </Col>
        </Row>
        {/*<References />*/}
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <S.TitleText level={2}>Job Schedules</S.TitleText>
        <ScheduleCard direction="vertical" />
        {/*<S.Space />
        <S.ScrollWrapper>
          <ActivityStory />
        </S.ScrollWrapper>*/}
      </S.RightSideCol>
    </Row>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {desktopLayout}
    </>
  );
};

export default DashboardPage;
