import React from 'react';
import * as S from './ScheduleNotFound.styles';
import { Card } from '@app/components/common/Card/Card';
import { Empty } from '@app/components/common/Empty/Empty';

export const ScheduleNotFound: React.FC = () => {
  return (
    <Card padding="1rem" style={{ marginTop: 10 }}>
      <S.CardBody>
        <Empty message={'No scheduled jobs for that day'} />
      </S.CardBody>
    </Card>
  );
};
