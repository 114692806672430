import React from 'react';
import * as S from './RecentCard.styles';
import { Service } from '@app/api/service.api';
import { Dates } from '@app/constants/Dates';
import { Link } from 'react-router-dom';
import BS01 from '../../../../../assets/images/service-backgounds/background-service-01.jpg';
import BS02 from '../../../../../assets/images/service-backgounds/background-service-02.jpg';
import BS03 from '../../../../../assets/images/service-backgounds/background-service-03.jpg';
import BS04 from '../../../../../assets/images/service-backgounds/background-service-04.jpg';
import BS05 from '../../../../../assets/images/service-backgounds/background-service-05.jpg';

interface RecentCardProps {
    recentItem: Service;
}

export const RecentCard: React.FC<RecentCardProps> = ({ recentItem }) => {
    const backgrounds = [BS01, BS02, BS03, BS04, BS05];

    return (
        <S.Card padding={0} $img={backgrounds[Math.floor(Math.random() * backgrounds.length)]}>
            <S.NftImage src={backgrounds[Math.floor(Math.random() * backgrounds.length)]} />
            <S.NftInfo>
                <S.InfoRow>
                    <S.Title>
                        <Link to={`/services/service/${recentItem.serviceid}`} target="_blank">
                            {atob(recentItem.reference)}
                        </Link>
                    </S.Title>
                </S.InfoRow>
                <S.InfoHeader>
                    <S.InfoText>for {recentItem.customername}</S.InfoText>
                </S.InfoHeader>
                <S.InfoFooter>
                    <S.CurrentBidWrapper>
                        <S.RegistrationNumber>{recentItem.servicerego.toUpperCase()}</S.RegistrationNumber>
                        <S.MakeModel>{recentItem.servicevehicle}</S.MakeModel>
                    </S.CurrentBidWrapper>
                    <S.CurrentBidWrapper>
                        <S.Status>{recentItem.servicestatusname}</S.Status>
                        <S.Due>{Dates.format(recentItem.servicedate, 'YYYY-MM-DD hh:mm A')}</S.Due>
                    </S.CurrentBidWrapper>
                </S.InfoFooter>
            </S.NftInfo>
        </S.Card>
    );
};
