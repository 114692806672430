import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SearchDropdown } from '../searchDropdown/SearchDropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './HeaderSearch.styles';
import { SearchResult, searchSummary } from '@app/api/summary.api';
import { SearchCategoryType } from '@app/constants/categoriesList';

export const HeaderSearch: React.FC = () => {
    const { mobileOnly, isTablet } = useResponsive();

    const { pathname } = useLocation();

    const [query, setQuery] = useState('');
    const [type, setType] = useState<SearchCategoryType>('services');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (query.length > 0) {
            setIsSearching(true);
            searchSummary(query, type)
                .then((data) => {
                    setSearchResults(data);
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    }, [query, type]);

    useEffect(() => {
        setModalOpen(false);
        setOverlayOpen(false);
    }, [pathname]);

    return (
        <>
            {mobileOnly && (
                <>
                    <Button
                        type={isModalOpen ? 'ghost' : 'text'}
                        icon={<S.SearchIcon onClick={() => setModalOpen(true)} />}
                    />
                    <S.SearchModal
                        open={isModalOpen}
                        closable={false}
                        footer={null}
                        onCancel={() => setModalOpen(false)}
                        destroyOnClose
                    >
                        <SearchDropdown
                            query={query}
                            setQuery={setQuery}
                            data={searchResults}
                            isOverlayOpen={isOverlayOpen}
                            setOverlayOpen={setOverlayOpen}
                            onSelectType={setType}
                            isSearching={isSearching}
                        />
                    </S.SearchModal>
                </>
            )}

            {isTablet && (
                <SearchDropdown
                    query={query}
                    setQuery={setQuery}
                    data={searchResults}
                    isOverlayOpen={isOverlayOpen}
                    setOverlayOpen={setOverlayOpen}
                    onSelectType={setType}
                    isSearching={isSearching}
                />
            )}
        </>
    );
};
