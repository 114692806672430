import { httpApi } from '@app/api/http.api';
import { Service } from '@app/api/service.api';
import { SearchCategoryType } from '@app/constants/categoriesList';

export interface SearchResult {
    id: string;
    reference: string;
    text: string;
    type: string;
}

export interface Customer {
    customerid: string;
    name: string;
    email: string;
    phone: string;
    address: string | null;
    comments: string | null;
    creadtedon: string;
}

export interface SummaryResults {
    services: {
        completed: number;
        pending: number;
        due: number;
    };
}

export interface CustomerServicesResults {
    data: Service[];
    total: number;
}

export interface CustomerResults {
    customer: Customer;
    serviceCount: number;
}

export const getSummary = (): Promise<SummaryResults> => {
    return httpApi.get<SummaryResults>('/summary').then(({ data }) => data);
};

export const searchSummary = (query: string, type: SearchCategoryType): Promise<SearchResult[]> => {
    return httpApi.get<SearchResult[]>(`/summary/search/${type}?query=${query}`).then(({ data }) => data);
};
