import React from 'react';
import { Col, Row } from 'antd';
import { StatisticsInfo } from '../StatisticsInfo/StatisticsInfo';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './StatisticsCard.styles';

interface StatisticsCardProps {
  name: string;
  value: number;
  color: string;
  icon?: React.ForwardRefExoticComponent<any>;
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({ name, value, color, icon }) => {
  const { isTablet: isTabletOrHigher } = useResponsive();

  return (
    <S.StatisticCard padding="0.5rem">
      <Row wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        <Col>
          <S.IconWrapper>
            <S.Icon component={icon} style={{ color: color }} />
          </S.IconWrapper>
        </Col>

        <Col flex={1}>
          <Row justify="space-between" align="middle" wrap={false}>
            <Col>
              <StatisticsInfo name={name} value={value} />
            </Col>
            <Col style={{ marginRight: 5 }}>
              <S.ValueText>{value}</S.ValueText>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.StatisticCard>
  );
};
