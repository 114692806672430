import React from 'react';
import { ScheduleNotFound } from './TreatmentNotFound/ScheduleNotFound';
import { Service } from '@app/api/service.api';
import { P1 } from '@app/components/common/typography/P1/P1';
import { ScheduleListItem } from '@app/components/common/Schedule/ServiceCalender/ScheduleListItem/ScheduleListItem';
import { Space } from 'antd';

interface TreatmentPanelProps {
  direction: 'vertical' | 'horizontal';
  events?: Service[];
}

export const SchedulePanel: React.FC<TreatmentPanelProps> = ({ events, direction }) => {
  const marginLR = direction === 'vertical' ? 0 : 10;

  if (events?.length) {
    return (
      <Space direction="vertical" style={{ display: 'flex', margin: 10 }}>
        <P1>{`${events.length} Schedules found`}</P1>
        <Space
          direction={direction}
          style={{ display: 'flex', marginTop: 10, marginBottom: 10, marginLeft: marginLR, marginRight: marginLR }}
        >
          {events.map((event) => {
            return <ScheduleListItem key={event.serviceid} event={event} />;
          })}
        </Space>
      </Space>
    );
  } else {
    return <ScheduleNotFound />;
  }
};
