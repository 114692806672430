import React from 'react';
import { Empty as EmptyContent } from 'antd';
import * as S from './Empty.styles';

export interface EmptyProps {
  message?: string;
}

export const Empty: React.FC<EmptyProps> = ({ message }) => {
  return (
    <S.EmptyWrapper>
      <EmptyContent
        image={EmptyContent.PRESENTED_IMAGE_SIMPLE}
        description={<span>{message ? message : 'No Data Found!'}</span>}
      />
    </S.EmptyWrapper>
  );
};
