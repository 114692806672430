import React from 'react';
import { Col, Row } from 'antd';
import { StatisticsCard } from '@app/components/common/Static/StatisticsCard/StatisticsCard';
import { SummaryResults } from '@app/api/summary.api';
import { CheckCircleOutlined, HourglassOutlined, WarningOutlined } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

export const ServiceStatistics: React.FC<SummaryResults> = (services) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const colors = themeObject[theme];

  if (services.services == null) {
    return <></>;
  }

  return (
    <Row gutter={[30, 30]}>
      <Col xs={24} sm={24} md={8}>
        <StatisticsCard
          name="Completed"
          value={services.services.completed}
          color={colors.statics.completed}
          icon={CheckCircleOutlined}
        />
      </Col>
      <Col xs={24} sm={24} md={8}>
        <StatisticsCard
          name="Pending"
          value={services.services.pending}
          color={colors.statics.pending}
          icon={HourglassOutlined}
        />
      </Col>
      <Col xs={24} sm={24} md={8}>
        <StatisticsCard
          name="Due past"
          value={services.services.due}
          color={colors.statics.due}
          icon={WarningOutlined}
        />
      </Col>
    </Row>
  );
};
