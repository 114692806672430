import React, { useMemo } from 'react';
import { List } from 'antd';
import { HashLink } from 'react-router-hash-link';
import * as S from './SearchResults.styles';
import { SearchResult } from '@app/api/summary.api';

interface SearchResultsProps {
    results: SearchResult[];
}

export const SearchResults: React.FC<SearchResultsProps> = ({ results }) => {
    const resultsList = useMemo(
        () => (
            <List
                split={false}
                dataSource={results}
                renderItem={(item) => {
                    let category = `${item.type}s`;
                    let subCategory = `${item.type}`;

                    if (item.type === 'inventory') {
                        category = 'inventory';
                        subCategory = 'item';
                    }
                    return (
                        <HashLink to={`${category}/${subCategory}/${item.id}`}>
                            <List.Item>
                                <S.Text>{`${atob(item.reference)} - ${item.text}`}</S.Text>
                            </List.Item>
                        </HashLink>
                    );
                }}
            />
        ),
        [results],
    );

    return <S.SearchResultsWrapper>{resultsList}</S.SearchResultsWrapper>;
};
