import { shadeColor } from '@app/utils/utils';
import { graphic } from 'echarts';
import { BASE_COLORS, primaryBlue, primaryDark, primaryGreen, primaryRed, primaryYellow } from '../constants';
import { ITheme } from '../types';

const chartColors = {
    chartTooltipLabel: '#6a7985',
    chartColor1: '#339CFD',
    chartColor1Tint: '#339CFD', // update
    chartColor2: '#dc88f5',
    chartColor2Tint: '#dc88f5', // update
    chartColor3: '#FFB765',
    chartColor3Tint: '#FFB765', // update
    chartColor4: '#306955',
    chartColor4Tint: '#306955', // update
    chartColor5: '#ff3d71',
    chartColor5Tint: '#ff3d71', // update
    chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(51, 156, 253, 0.35)',
        },
        {
            offset: 1,
            color: 'rgba(51, 156, 253, 0)',
        },
    ]),
    chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 82, 82, 0.35)',
        },
        {
            offset: 1,
            color: 'rgba(255, 82, 82, 0)',
        },
    ]),
    chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(255, 255, 255, 0)',
        },
        {
            offset: 1,
            color: 'rgba(255, 82, 82, 0.5)',
        },
    ]),
};

export const darkColorsTheme: ITheme = {
    primary: primaryGreen['500'],
    primary1: '#7568f6',
    light: '#696969',
    secondary: primaryYellow['500'],
    error: primaryRed['300'],
    warning: primaryYellow['800'],
    success: primaryGreen['600'],
    background: primaryDark['450'],
    secondaryBackground: '#1c2137',
    secondaryBackgroundSelected: shadeColor('#1c2137', -5),
    additionalBackground: '#1D203E',
    collapseBackground: '#1D203E',
    timelineBackground: '#f5f5f5',
    siderBackground: primaryDark['400'],
    spinnerBase: '#339CFD',
    scroll: '#797C9A',
    border: '#ffffff',
    borderNft: '#797C9A',
    textMain: '#ffffff',
    textLight: '#9A9B9F',
    textSuperLight: '#444',
    textSecondary: '#ffffff',
    textDark: '#404040',
    textFieldViewerTitle: primaryDark['100'],
    textFormLabel: primaryDark['100'],
    textNftLight: primaryYellow['300'],
    textSiderPrimary: primaryYellow['300'],
    textSiderSecondary: primaryDark['100'],
    textTableLink: primaryYellow['500'],
    subText: '#a9a9a9',
    shadow: 'rgba(0, 0, 0, 0.07)',
    boxShadow: 'none',
    boxShadowHover: 'none',
    boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    boxShadowNftSecondary:
        '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    dashboardMapBackground: '#25284b',
    dashboardMapCircleColor: '#ACAEC1',
    dashboardMapControlDisabledBackground: '#7e7e7e',
    notificationSuccess: '#EFFFF4',
    notificationPrimary: '#D7EBFF',
    notificationWarning: '#FFF4E7',
    notificationError: '#FFE2E2',
    heading: BASE_COLORS.white,
    borderBase: '#a9a9a9',
    disable: '#7e7e7e',
    disabledBg: '#1c2137',
    layoutBodyBg: primaryDark['500'],
    layoutHeaderBg: primaryDark['500'],
    layoutSiderBg: primaryDark['450'],
    inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
    itemHoverBg: '#1c2137',
    backgroundColorBase: '#1c2137',
    avatarBg: '#1c2137',
    alertTextColor: '#000',
    breadcrumb: '#a9a9a9',
    icon: '#a9a9a9',
    iconHover: '#ffffff',
    spinnerBackground: primaryYellow['500'],
    stepIcon: primaryYellow['500'],
    stepCompletedIcon: primaryYellow['500'],
    stepCompletedTitle: primaryYellow['500'],
    dividerColor: primaryDark['300'],
    statics: {
        completed: primaryGreen['600'],
        pending: primaryYellow['600'],
        due: primaryRed['300'],
    },
    icons: {
        success: primaryGreen['400'],
        error: primaryRed['500'],
        warning: primaryYellow['800'],
        info: primaryBlue['A200'],
        delete: primaryRed['500'],
    },
    ...chartColors,
};

export const antDarkColorsTheme = {
    successBg: '#e6fff2',
    successBorder: '#79fcc4',
};
