import React from 'react';
import { Space, SpinProps } from 'antd';
import * as S from './Spinner.styles';

export const Spinner: React.FC<SpinProps> = (props) => {
    return (
        <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'center' }}>
            <S.Spinner {...props} />
        </Space>
    );
};
